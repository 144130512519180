.quill {
  width: 100%;
  min-height: 500px;
}

#content-wrapper {
  snippet {
    > * {
      border-left-style: dotted;
      border-left-width: thin;
      padding-left: 5px;
      border-left-color: purple;
    }
    &:hover > * {
      background-color: #ededed;
    }
  }
}
