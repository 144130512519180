@import 'tocbot/src/scss/tocbot';

#live-wrapper {
  > :first-child {
    padding: 0px;
    min-width: 15vw;
    max-width: 75%;
  }
  #li {
    margin-top: 10px;
  }
  #toc {
    padding-top: 10%;
    margin-left: 4%;
    max-height: fit-content;
    max-width: 250px;
    word-wrap: normal;
    position: fixed;
  }
  #content-wrapper {
    padding-top: 30px;
    margin-left: 5%;
    max-width: 50%;
    margin-bottom: 70vh;
    snippet {
      > * { 
        border-left-style: unset;
        background-color: unset;
        padding-left: 0;
      }
    }
  }

  @media (max-width: 1400px) {
    #toc {
      margin-left: 4%;
      max-width: 185px;
    }
    #content-wrapper {
      max-width: 50%;
    }
  }

  @media (max-width: 1200px) {
    #toc {
      margin-left: 3%;
      max-width: 185px;
    }
    #content-wrapper {
      max-width: 70%;
    }
  }

  @media (max-width: 1000px) {
    #toc {
      margin-left: 2%;
      max-width: 185px;
    }
    #content-wrapper {
      max-width: 75%;
      padding-left: 4%;
    }
  }

  @media (max-width: 800px) {
    #toc {
      margin-left: 0%;
      max-width: 185px;
    }
    #content-wrapper {
      padding-left: 12%;
      max-width: 75%;
    }
  }
  #toc-list {
    position: relative;
  }

  //Tiny screens slide out menu
}
