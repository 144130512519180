.react-grid-layout {
  position: relative;
  .react-resizable-handle {
    display: none;
  }
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none !important;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
