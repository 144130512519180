.mindmap-separate {
  display: flex;
  align-items: center;
  justify-content: space-between;
  hr {
    width: 90%;
    margin: 5px 0;
  }
  margin-bottom: 20px;
}

.maidmap-editor {
  display: none;
  height: calc(50vh - 100px);
  &.show {
    display: block;
  }
}

@media (max-width: 767px) {
  .maidmap-editor {
    height: calc(50vh - 55px);
  }
}
